import {
  ActivityReportingNotificationData,
  Bank,
  CommentCCNotificationData,
  CommentNotificationData,
  CommentTaskAssignedNotificationData,
  CrbEditInfoNotificationData,
  CrbmLicenseStatusUpdateNotificationData,
  CrbUnarchivedNotificationData,
  Dispensary,
  DueDiligenceStatus,
  ExpiringBusinessLicenseNotificationData,
  ExpiringEmployeeLicenseNotificationData,
  FiEditInfoNotificationData,
  FincenReportType,
  FiQuestionnaireCompleteNotificationData,
  getCommentDetails,
  MainFeatures,
  MinifiedDispensary,
  MUOBankResponse,
  NewAutogeneratedReviewAvailableNotificationData,
  NewDepositNotificationData,
  NewDispensaryToReviewNotificationData,
  NewDocumentUploadedNotificationData,
  NewFincenReportNotificationData,
  NewMarketplaceApplicationNotificationData,
  NewPayqwickAdminUserNotificationData,
  Notification,
  NotificationTypes,
  RecurringQuestionnaireNotificationData,
  RequestDocumentUploadNotificationData,
  SftpParsingFailureNotificationData,
  UserTaskAssignedNotificationData
} from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { BanksApi, FinCENApi, NotificationsApi } from 'api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useHistory } from 'react-router';
import { getCommentStore } from 'stores/CommentStore';
import { getCrbBankStore } from 'stores/CrbBankStore';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getFiBankStore } from 'stores/FiBankStore';
import { getFiDispensaryStore } from 'stores/FiDispensaryStore';
import { getNotificationStore } from 'stores/NotificationStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { getUserStore } from 'stores/UserStore';
import { Button, Drawer } from 'ui';
import { SalesNotificationModal } from 'ui/modules/sales-notification-modal';
import palette from 'ui/theme/palette';
import { DateTimeHelpers } from 'util/dateTime.util';
import {
  goToComment,
  renderNotificationDescription,
  renderNotificationIcon,
  renderNotificationTitle
} from 'util/notifications.util';
import { orgFeatureEnabled } from 'util/org.util';
import { ClearNotificationsModal } from './clear-notifications-modal';
import { ZeroState } from './zero-state';

interface Props {
  org: Bank | Dispensary;
  drawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
}

export const NotificationsDrawer: React.FC<Props> = (props) => {
  const { documentSharing }: { documentSharing: boolean } = useFlags();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const fincenApi = new FinCENApi();
  const notificationsApi = new NotificationsApi();
  const banksApi = new BanksApi();

  const [notification, setNotification] = React.useState<Notification | null>(null);
  const [isSalesDrawerOpen, setIsSalesDrawerOpen] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const bankStore = getFiBankStore();
  const crbBankStore = getCrbBankStore();
  const crbDispensaryStore = getCrbDispensaryStore();
  const fiDispensaryStore = getFiDispensaryStore();
  const commentStore = getCommentStore();
  const notificationStore = getNotificationStore();
  const snackbarStore = getSnackbarStore();
  const userStore = getUserStore();

  const closeDrawer = () => {
    props.setDrawerOpen(false);
  };

  const handleDownload = async (signedUrl: string) => {
    try {
      const downloadUrl = signedUrl;
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', downloadUrl);
      linkElement.click();
      linkElement.remove();
    } catch (e) {
      console.log(e);
      snackbarStore.showErrorSnackbarMessage('There was an issue downlading the report.');
    }
  };

  const isInvited = (dispensary: MinifiedDispensary | MUOBankResponse) => {
    const status = dispensary.assigned_onboarding_template.status;

    return (
      status === DueDiligenceStatus.BANK_IN_PROGRESS ||
      status === DueDiligenceStatus.BANK_PENDING ||
      status === DueDiligenceStatus.GCV_PENDING ||
      status === DueDiligenceStatus.GCV_IN_PROGRESS ||
      status === DueDiligenceStatus.GCV_AWAITING_REVIEW ||
      status === DueDiligenceStatus.GCV_APPROVED
    );
  };

  const goToNotification = async (notification: Notification) => {
    await notificationsApi.markNotificationSeen(notification.id ? notification.id : '');
    notificationStore.removeNotification(notification.id ? notification.id : '');

    const appType = props.org.orgType === 'bank' ? 'fi' : 'crb';
    const notificationData = notification.data;

    switch (notification.type) {
      case NotificationTypes.ACTIVITY_REPORTING:
        closeDrawer();
        handleDownload((notificationData as ActivityReportingNotificationData).signedS3Path);
        break;
      case NotificationTypes.SFTP_UPLOAD_REPORT:
      case NotificationTypes.SFTP_NO_DATA:
      case NotificationTypes.SFTP_PARSING_FAILURE:
        closeDrawer();
        history.push(
          `/secure/fi/data?tab=imports&reportId=${
            (notificationData as SftpParsingFailureNotificationData).sftpUploadReportId
          }`
        );
        break;
      case NotificationTypes.SFTP_MISSED_SCHEDULE:
        closeDrawer();
        history.push(`/secure/fi/data?tab=imports`);
        break;
      case NotificationTypes.COMPLETED_DISPENSARY_REVIEW:
        closeDrawer();
        history.push(`/secure/crb/dashboard`);
        break;
      case NotificationTypes.COMPLETE_EVS:
        closeDrawer();
        history.push(`/secure/crb/identity-verify`);
        break;
      case NotificationTypes.BANKING_PARTNER_IDENTIFIED:
        closeDrawer();
        // TODO: get ID from notification data once it's there
        const status = crbDispensaryStore.getDefaultProvider().templates.value.onboarding.status;
        if (
          status !== DueDiligenceStatus.BANK_AWAITING_REVIEW &&
          status !== DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS &&
          status !== DueDiligenceStatus.BANK_APPROVED
        ) {
          history.push('/secure/crb/found-bank');
        }
        break;
      case NotificationTypes.EXPIRED_BUSINESS_LICENSE:
        closeDrawer();
        if (!documentSharing) {
          history.push(
            `/secure/crb/my-providers/provider-details/${
              (notificationData as ExpiringBusinessLicenseNotificationData).bank_id
            }/requirement/business_licenses`
          );
        } else {
          history.push(`/secure/crb/company?tab=licenses`);
        }
        break;
      case NotificationTypes.EXPIRED_EMPLOYEE_LICENSE:
        closeDrawer();
        history.push(
          `/secure/crb/my-providers/provider-details/${
            (notificationData as ExpiringEmployeeLicenseNotificationData).bank_id
          }/requirement/employee_licenses`
        );
        break;
      case NotificationTypes.EXPIRING_BUSINESS_LICENSE:
        closeDrawer();
        history.push(
          `/secure/crb/my-providers/provider-details/${
            (notificationData as ExpiringBusinessLicenseNotificationData).bank_id
          }/requirement/business_licenses`
        );
        break;
      case NotificationTypes.EXPIRING_EMPLOYEE_LICENSE:
        closeDrawer();
        history.push(
          `/secure/crb/my-providers/provider-details/${
            (notificationData as ExpiringEmployeeLicenseNotificationData).bank_id
          }/requirement/employee_licenses`
        );
        break;
      case NotificationTypes.REQUEST_DOCUMENT_UPLOAD:
        closeDrawer();
        history.push(
          `/secure/crb/my-providers/provider-details/${
            (notificationData as RequestDocumentUploadNotificationData).bank_id
          }/requirement/${(notificationData as RequestDocumentUploadNotificationData).requirement_id}`
        );
        break;
      case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_RETAIL:
        setNotification(notification);
        setIsSalesDrawerOpen(true);
        break;
      case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_NON_RETAIL:
        setNotification(notification);
        setIsSalesDrawerOpen(true);
        break;
      case NotificationTypes.SALES_INGESTION_FAILURE:
        setNotification(notification);
        setIsSalesDrawerOpen(true);
        break;
      case NotificationTypes.NEW_DISPENSARY_TO_REVIEW:
        closeDrawer();
        const dispId = (notificationData as NewDispensaryToReviewNotificationData).id;
        const disp = [...fiDispensaryStore.dispensaries, ...fiDispensaryStore.dispensariesArchived].find(
          (disp) => disp.id === dispId
        );
        const dueDiligenceStatus = disp?.assigned_onboarding_template.status;
        if (
          dueDiligenceStatus === DueDiligenceStatus.BANK_APPROVED ||
          dueDiligenceStatus === DueDiligenceStatus.BANK_DISCONNECTED
        ) {
          history.push(`/secure/fi/accounts/${dispId}/view`);
          //update_required does not have a routing preference in accounts tab
        } else if (dueDiligenceStatus !== DueDiligenceStatus.UPDATE_REQUIRED) {
          history.push(`/secure/fi/accounts/${dispId}/review`);
        }
        break;
      case NotificationTypes.NEW_DEPOSIT:
        closeDrawer();
        history.push(`/secure/fi/deposits?id=${(notificationData as NewDepositNotificationData).deposit_id}`);
        break;
      case NotificationTypes.NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
        closeDrawer();
        const reviewId = (notificationData as NewAutogeneratedReviewAvailableNotificationData).reviewId;
        history.push(`/secure/fi/monitoring/review/${reviewId}`);
        break;
      case NotificationTypes.BATCH_NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
        closeDrawer();
        history.push('/secure/fi/monitoring');
        break;
      case NotificationTypes.MARKETPLACE_NEW_APPLICATION:
        closeDrawer();
        const crbId = (notificationData as NewMarketplaceApplicationNotificationData).crb_id;
        history.push(`/secure/fi/accounts/${crbId}/review`);
        break;
      case NotificationTypes.NEW_DOCUMENT_UPLOADED_FOR_CRB:
        closeDrawer();
        history.push(`/secure/crb/company?tab=documents`);
        break;
      case NotificationTypes.NEW_FINCEN_REPORT:
        closeDrawer();
        const newReportId = (notificationData as NewFincenReportNotificationData).id;

        fincenApi.getReportById(bankStore.bank.id, newReportId).then((report) => {
          if (report.report_type === FincenReportType.SAR) {
            history.push(`/secure/fi/fincen/sar/${newReportId}`);
          } else {
            history.push(`/secure/fi/fincen/ctr/${newReportId}`);
          }
        });
        break;
      case NotificationTypes.NEW_DOCUMENT_UPLOADED:
        closeDrawer();
        const documentData = notificationData as NewDocumentUploadedNotificationData;
        const dispensary = fiDispensaryStore.dispensaries.find(
          (d) => d.name === documentData.dispensary_name
        );
        if (dispensary?.id) {
          if (documentData && documentData.requirement_id) {
            const isActive =
              dispensary.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED;
            isActive
              ? history.push(
                  `/secure/fi/accounts/${dispensary.id}/view/requirement/${documentData.requirement_id}`
                )
              : history.push(`/secure/fi/accounts/${dispensary.id}/review`);
          } else {
            history.push(`/secure/fi/accounts/${dispensary.id}/view?tab=documents`);
          }
        } else {
          history.push(`/secure/fi/accounts`);
        }
        break;
      case NotificationTypes.COMMENT_MENTION:
      case NotificationTypes.COMMENT_REPLY:
        const data = notificationData as CommentNotificationData;

        const commentUrl = await goToComment(appType, data.commentType, data.idComponents, true);

        if (commentUrl) {
          history.push(commentUrl);
        }

        closeDrawer();
        break;
      case NotificationTypes.COMMENT_CC:
        const ccCommentData = notificationData as CommentCCNotificationData;
        const commentDetails = getCommentDetails(
          'fi',
          ccCommentData.commentType,
          ccCommentData.idComponents,
          undefined,
          undefined,
          DueDiligenceStatus.BANK_APPROVED
        );

        history.push(commentDetails.url);

        closeDrawer();
        break;
      case NotificationTypes.ONE_TIME_QUESTIONNAIRE:
      case NotificationTypes.RECURRING_QUESTIONNAIRE:
        const questionnaire_result_id = (notificationData as RecurringQuestionnaireNotificationData)
          ?.questionnaire_result_id;
        if (questionnaire_result_id) {
          history.push(`/secure/crb/inbox?taskId=${questionnaire_result_id}`);
        }
        break;
      case NotificationTypes.CRBM_LICENSE_STATUS_UPDATE:
        closeDrawer();
        const licenseData = notificationData as CrbmLicenseStatusUpdateNotificationData;

        if (appType === 'crb') {
          history.push(
            `/secure/crb/my-providers/provider-details/business_licenses?documentId=${licenseData.documentId}`
          );
        } else {
          history.push(
            `/secure/fi/accounts/${licenseData.dispensaryId}/view/requirement/business_licenses?documentId=${licenseData.documentId}`
          );
        }
        break;
      case NotificationTypes.FI_QUESTIONNAIRE_COMPLETE:
        closeDrawer();
        history.push(
          `/secure/fi/tasks/questionnaire/overview/${
            (notificationData as FiQuestionnaireCompleteNotificationData).template_id
          }/result/${(notificationData as FiQuestionnaireCompleteNotificationData).questionnaire_result_id}`
        );
        break;
      case NotificationTypes.POS_AUTH_FAILURE:
        closeDrawer();
        history.push(`/secure/crb/company?tab=integrations`);
        break;
      case NotificationTypes.USER_TASK_ASSIGNED:
        closeDrawer();
        const note = notificationData as UserTaskAssignedNotificationData;
        history.push(`/secure/fi/tasks?taskId=${note.task_id}`);
        break;
      case NotificationTypes.COMMENT_TASK_ASSIGNED:
        closeDrawer();
        const comment = notificationData as CommentTaskAssignedNotificationData;
        if (appType === 'crb') {
          history.push(`/secure/crb/inbox?taskId=${comment.task_id}`);
        } else {
          history.push(`/secure/fi/tasks?taskId=${comment.task_id}`);
        }
        break;
      case NotificationTypes.NEW_PAYQWICK_ADMIN_USER:
        closeDrawer();
        const pqAdminData = notificationData as NewPayqwickAdminUserNotificationData;
        history.push(`/secure/fi/accounts/${pqAdminData.crbId}/view?tab=account-owners`);
        break;
      case NotificationTypes.USER_ADDED:
      case NotificationTypes.PROFILE_UPDATED_CRB:
        closeDrawer();
        history.push(`/secure/crb/users`);
        break;
      case NotificationTypes.PROFILE_UPDATED_USER:
        closeDrawer();
        history.push(`/secure/crb/users/settings`);
        break;
      case NotificationTypes.DISCONNECT_CRB:
        closeDrawer();
        history.push(`/secure/crb/my-providers`);
        break;
      case NotificationTypes.UNARCHIVE_CRB:
        closeDrawer();
        const unarchiveData = notificationData as CrbUnarchivedNotificationData;
        history.push(`/secure/crb/my-providers/provider-details/${unarchiveData.service_provider_id}`);
        break;
      case NotificationTypes.CRB_TASK_ASSIGNED:
        closeDrawer();
        const crbTaskData = notificationData as UserTaskAssignedNotificationData;
        history.push(`/secure/crb/inbox?taskId=${crbTaskData.task_id}`);
        break;
      case NotificationTypes.FI_EDIT_ADDITIONAL_INFO:
        closeDrawer();
        const fiAdditionalInfoData = notificationData as FiEditInfoNotificationData;
        const fiAdditionalInfoCrbs = await banksApi.getMinifiedDispensaries(fiAdditionalInfoData.fi_id);
        const fiAdditionalInfoCrb = fiAdditionalInfoCrbs.find(
          (crb) => crb.id === crbDispensaryStore.currentDispensary.id
        );

        if (fiAdditionalInfoCrb && isInvited(fiAdditionalInfoCrb)) {
          const templateId = crbBankStore.banks[fiAdditionalInfoData.fi_id]
            ? crbBankStore.banks[fiAdditionalInfoData.fi_id].templates.value.onboarding.template_id
            : '';
          const templateResultId = crbBankStore.banks[fiAdditionalInfoData.fi_id]
            ? crbBankStore.banks[fiAdditionalInfoData.fi_id].templates.value.onboarding.template_result_id
            : '';

          history.push(
            `/secure/crb/provider-onboarding/bank/${fiAdditionalInfoData.fi_id}/template/${templateId}/result/${templateResultId}?step=additional-information`
          );
        } else {
          history.push(
            `/secure/crb/my-providers/provider-details/${fiAdditionalInfoData.fi_id}?tab=additional-information`
          );
        }
        break;
      case NotificationTypes.FI_EDIT_BUSINESS_DETAILS:
        closeDrawer();
        const fiBusinessDetailsData = notificationData as FiEditInfoNotificationData;
        const fiBusinessDetailsCrbs = await banksApi.getMinifiedDispensaries(fiBusinessDetailsData.fi_id);
        const fiBusinessDetailsCrb = fiBusinessDetailsCrbs.find(
          (crb) => crb.id === crbDispensaryStore.currentDispensary.id
        );

        if (fiBusinessDetailsCrb && isInvited(fiBusinessDetailsCrb)) {
          const templateId = crbBankStore.banks[fiBusinessDetailsData.fi_id]
            ? crbBankStore.banks[fiBusinessDetailsData.fi_id].templates.value.onboarding.template_id
            : '';
          const templateResultId = crbBankStore.banks[fiBusinessDetailsData.fi_id]
            ? crbBankStore.banks[fiBusinessDetailsData.fi_id].templates.value.onboarding.template_result_id
            : '';

          history.push(
            `/secure/crb/provider-onboarding/bank/${fiBusinessDetailsData.fi_id}/template/${templateId}/result/${templateResultId}?step=business-details`
          );
        } else {
          history.push(`/secure/crb/company`);
        }
        break;
      case NotificationTypes.FI_EDIT_OPERTATION_DETAILS:
        closeDrawer();
        const fiOperationDetailsData = notificationData as FiEditInfoNotificationData;
        const fiOperationDetailsCrbs = await banksApi.getMinifiedDispensaries(fiOperationDetailsData.fi_id);
        const fiOperationDetailsCrb = fiOperationDetailsCrbs.find(
          (crb) => crb.id === crbDispensaryStore.currentDispensary.id
        );

        if (fiOperationDetailsCrb && isInvited(fiOperationDetailsCrb)) {
          const templateId = crbBankStore.banks[fiOperationDetailsData.fi_id]
            ? crbBankStore.banks[fiOperationDetailsData.fi_id].templates.value.onboarding.template_id
            : '';
          const templateResultId = crbBankStore.banks[fiOperationDetailsData.fi_id]
            ? crbBankStore.banks[fiOperationDetailsData.fi_id].templates.value.onboarding.template_result_id
            : '';

          history.push(
            `/secure/crb/provider-onboarding/bank/${fiOperationDetailsData.fi_id}/template/${templateId}/result/${templateResultId}?step=operational-details`
          );
        } else {
          history.push(`/secure/crb/company?tab=operational`);
        }

        break;
      case NotificationTypes.CRB_EDIT_ADDITIONAL_INFO:
        closeDrawer();
        const crbAdditionalInfoData = notificationData as CrbEditInfoNotificationData;
        history.push(`/secure/fi/accounts/${crbAdditionalInfoData.crb_id}/view?tab=additional-info`);
        break;
      case NotificationTypes.CRB_EDIT_BUSINESS_DETAILS:
        closeDrawer();
        const crbBusinessDetailsData = notificationData as CrbEditInfoNotificationData;
        history.push(`/secure/fi/accounts/${crbBusinessDetailsData.crb_id}/view?tab=general`);
        break;
      case NotificationTypes.CRB_EDIT_OPERTATION_DETAILS:
        closeDrawer();
        const crbOperationDetailsData = notificationData as CrbEditInfoNotificationData;
        history.push(`/secure/fi/accounts/${crbOperationDetailsData.crb_id}/view?tab=operational`);
        break;
      case NotificationTypes.OTHER_SP_EDIT_BUSINESS_DETAILS:
        closeDrawer();
        const fiOtherBusinessDetailsData = notificationData as CrbEditInfoNotificationData;
        history.push(`/secure/fi/accounts/${fiOtherBusinessDetailsData.crb_id}/view?tab=general`);
        break;
      case NotificationTypes.OTHER_SP_EDIT_OPERTATION_DETAILS:
        closeDrawer();
        const fiOtherOperationDetailsData = notificationData as CrbEditInfoNotificationData;
        history.push(`/secure/fi/accounts/${fiOtherOperationDetailsData.crb_id}/view?tab=operational`);
        break;
      case NotificationTypes.ONBOARD_WITH_TRACE_DRAWER:
        closeDrawer();
        history.push(`/secure/crb/company?tab=integrations`);
        break;
      default:
        break;
    }
  };

  return (
    <Drawer
      id="notifications-drawer"
      title="Notifications"
      anchor="right"
      open={props.drawerOpen}
      onClose={() => closeDrawer()}
      width={isMobile ? '360px' : '384px'}
      bottomActionPadding="1rem 1rem 4rem 1rem"
      bottomActionMargin="0 0 55px 0"
      bottomAction={
        notificationStore.notifications.length > 0 && (
          <Button
            color="default-outlined"
            onClick={() => {
              setConfirmationModal(true);
            }}
            label="Clear all"
          />
        )
      }
    >
      {notificationStore.notifications.length === 0 && (
        <ZeroState
          width="336px"
          title={'There are no new notifications'}
          icon="/img/empty-states/NoNotifications.svg"
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ overflow: 'auto' }}>
          {Object.values(
            notificationStore.notifications.reduce<{ [key: string]: Notification[] }>((acc, curr) => {
              // group the types into categories then flatten into single array and map
              const userCanViewDeposits = orgFeatureEnabled(bankStore.bank, MainFeatures.Deposits);
              if (
                !userCanViewDeposits &&
                (curr.type === NotificationTypes.MAX_DEPOSIT_UPDATED ||
                  curr.type === NotificationTypes.NEW_DEPOSIT)
              ) {
                return acc;
              }
              const category = renderNotificationTitle(curr.type);
              if (category) {
                (acc[category] = acc[category] || []).push(curr);
              }
              return acc;
            }, {})
          )
            .flat()
            .sort((a, b) => {
              const dateA = DateTimeHelpers.parseFromISOString(a.created_date, props.org.iana_timezone);
              const dateB = DateTimeHelpers.parseFromISOString(b.created_date, props.org.iana_timezone);
              if (dateA > dateB) {
                return 1;
              } else if (dateA < dateB) {
                return -1;
              }
              return 0;
            })
            .map((n) => {
              return (
                <Box
                  key={n.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    borderBottom: `1px solid ${palette.background.secondary}`,
                    cursor: 'pointer',
                    justifyContent: 'space-around',
                    padding: '14px',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.primary.light
                    }
                  }}
                  onClick={() => goToNotification(n)}
                >
                  <Box>{renderNotificationIcon(n.type)}</Box>
                  <Box style={{ width: '285px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '24px',
                        color: (theme) => theme.palette.text.primary
                      }}
                    >
                      <Box style={{ flexGrow: 1 }}>{renderNotificationTitle(n.type)}</Box>
                      <Box
                        sx={{
                          fontSize: '10px',
                          fontWeight: 700,
                          color: (theme) => theme.palette.text.secondary
                        }}
                      >
                        {DateTimeHelpers.formatISOToFriendlyDateString(
                          n.created_date,
                          props.org.iana_timezone
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        fontSize: '13px',
                        lineHeight: '17px',
                        fontWeight: 500,
                        color: (theme) => theme.palette.text.secondary,
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                    >
                      {renderNotificationDescription(n as Notification)}
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>

      <SalesNotificationModal
        isOpen={isSalesDrawerOpen}
        onClose={() => {
          setIsSalesDrawerOpen(false);
          setTimeout(() => {
            setNotification(null);
          }, 100);
        }}
        onComplete={() => {
          setIsSalesDrawerOpen(false);
          setTimeout(() => {
            setNotification(null);
          }, 100);
        }}
        notification={notification}
      />

      <ClearNotificationsModal
        isOpen={confirmationModal}
        onClose={() => {
          setConfirmationModal(false);
        }}
        onSubmit={() => {
          notificationsApi.clearAllNotifications(props.org.id, userStore.user.id);
          notificationStore.clearStore();
          setConfirmationModal(false);
          closeDrawer();
        }}
      />
    </Drawer>
  );
};
