import { BankOverview, DailySummary, IANATimezones } from '@gcv/shared';
import { BanksApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { AppViewStateStore } from 'stores/AppViewStateStore';
import { FiBankStore } from 'stores/FiBankStore';
import { DateTimeHelpers } from 'util/dateTime.util';

export interface PM {
  bankOverview: null | BankOverview;
  dailySummaries: DailySummary[];
  loadingDailySummaries: boolean;
  loadingBankOverview: boolean;
  timezone: IANATimezones;
  loadingDataError: boolean;
  chartUrl?: string;
}

@injectable()
export class FiDashboardRepo {
  @inject(BanksApi) private banksApi: BanksApi;
  @inject(AppViewStateStore) private appViewStore: AppViewStateStore;
  @inject(FiBankStore) private bankStore: FiBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {
    bankOverview: null,
    dailySummaries: [],
    loadingBankOverview: true,
    loadingDailySummaries: true,
    timezone: IANATimezones.America_NewYork,
    loadingDataError: false,
    chartUrl: ''
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = async () => {
    this.updateProgrammersModel({
      loadingDataError: false,
      loadingBankOverview: true,
      loadingDailySummaries: true
    });

    try {
      const [overview, summaries, chartUrl] = await Promise.all([
        this.fetchBankOverview(),
        this.fetchDailySummaries(),
        this.fetchSigmaUrl()
      ]);

      this.updateProgrammersModel({
        bankOverview: overview,
        dailySummaries: summaries,
        loadingBankOverview: false,
        loadingDailySummaries: false,
        timezone: this.bankStore.bank.iana_timezone,
        chartUrl: chartUrl?.url || ''
      });
    } catch (e) {
      this.updateProgrammersModel({
        loadingDataError: true,
        loadingBankOverview: false,
        loadingDailySummaries: false,
        timezone: this.bankStore.bank.iana_timezone
      });
    }
  };

  fetchSigmaUrl = async () => {
    if (this.bankStore.bank.sigma_config && this.bankStore.bank.sigma_config.enabled) {
      try {
        return await this.banksApi.getBankSigmaUrl(this.bankStore.bank.id);
      } catch (e) {
        throw new Error('There was an issue loading the embeded chart. Contact support for additional help');
      }
    } else {
      return undefined;
    }
  };

  fetchBankOverview = async () => {
    try {
      return await this.banksApi.getBankOverview(
        this.bankStore.bank.id,
        this.appViewStore.dashboardTimePeriod.dateRange.start,
        this.appViewStore.dashboardTimePeriod.dateRange.end
      );
    } catch (e) {
      // All back-end errors are now instances of ApiError. We have the option to catch these
      // errors here at the repo level, on a case-by-case basis, if interrogating the error and
      // re-wrapping it as a custom error makes sense.
      //
      // This might make sense if we are reusing a repo for more than one presenter. Otherwise, as
      // in this case, we'd just send the ApiError all the way up to the presenter. (We're catching
      // and rethrowing in this example, just to make room for these comments.)
      throw e;
    }
  };

  fetchDailySummaries = async () => {
    try {
      return await this.banksApi.getDailySummaries(
        this.bankStore.bank.id,
        this.appViewStore.dashboardTimePeriod.dateRange.start,
        this.appViewStore.dashboardTimePeriod.dateRange.end
      );
    } catch (e) {
      throw new Error('There was an issue loading page data. Contact support for additional help.');
    }
  };
}
